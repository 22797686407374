import React from 'react'
// import NavBar from './NavBar'

function Header() {
  return (
    <div class="leading-normal tracking-normal text-white ">
    {/* bg-gradient-to-r from-yellow-400 to-cyan-600 */}

    {/* <NavBar/> */}
           <br/>
           <br/>
           <br/>
           </div> 
  )
}

export default Header