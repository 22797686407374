import { Route, Routes } from "react-router-dom";
import Beranda from "./pages/Beranda";
import Layanan from "./pages/Layanan";
import Us from "./pages/TentangKami";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import Registrasi from "./pages/Registrasi";
import Header from "./components/Header";




function App() {
  return (
    <div className="App">
    
      <Header/>
    
      <NavBar/>
      <Routes>
        <Route path="/" element={<Beranda />} />
        <Route path="/Layanan" element={<Layanan />} />
        <Route path="/Registrasi" element={<Registrasi />} />
        <Route path="/tentangkami" element={<Us />} />
      </Routes>
<Footer/>
    </div>
  );
}

export default App;


      // var scrollpos = window.scrollY;
      // var header = document.getElementById("header");
      // var navcontent = document.getElementById("nav-content");
      // var navaction = document.getElementById("navAction");
      // var brandname = document.getElementById("brandname");
      // var toToggle = document.querySelectorAll(".toggleColour");

      // document.addEventListener("scroll", function () {
      //   /*Apply classes for slide in bar*/
      //   scrollpos = window.scrollY;

      //   if (scrollpos > 10) {
      //     header.classList.add("bg-white");
      //     navaction.classList.remove("bg-white");
      //     navaction.classList.add("gradient");
      //     navaction.classList.remove("text-gray-800");
      //     navaction.classList.add("text-white");
      //     //Use to switch toggleColour colours
      //     for (var i = 0; i < toToggle.length; i++) {
      //       toToggle[i].classList.add("text-gray-800");
      //       toToggle[i].classList.remove("text-white");
      //     }
      //     header.classList.add("shadow");
      //     navcontent.classList.remove("bg-gray-100");
      //     navcontent.classList.add("bg-white");
      //   } else {
      //     header.classList.remove("bg-white");
      //     navaction.classList.remove("gradient");
      //     navaction.classList.add("bg-white");
      //     navaction.classList.remove("text-white");
      //     navaction.classList.add("text-gray-800");
      //     //Use to switch toggleColour colours
      //     for (var j = 0; j < toToggle.length; j++) {
      //       toToggle[j].classList.add("text-white");
      //       toToggle[j].classList.remove("text-gray-800");
      //     }

      //     header.classList.remove("shadow");
      //     navcontent.classList.remove("bg-white");
      //     navcontent.classList.add("bg-gray-100");
      //   }
      // });
      
      
      // /*Toggle dropdown list*/
      // /*https://gist.github.com/slavapas/593e8e50cf4cc16ac972afcbad4f70c8*/

      // var navMenuDiv = document.getElementById("nav-content");
      // var navMenu = document.getElementById("nav-toggle");

      // document.onclick = check;
      // function check(e) {
      //   var target = (e && e.target) || (e && e.srcElement);

      //   //Nav Menu
      //   if (!checkParent(target, navMenuDiv)) {
      //     // click NOT on the menu
      //     if (checkParent(target, navMenu)) {
      //       // click on the link
      //       if (navMenuDiv.classList.contains("hidden")) {
      //         navMenuDiv.classList.remove("hidden");
      //       } else {
      //         navMenuDiv.classList.add("hidden");
      //       }
      //     } else {
      //       // click both outside link and outside menu, hide menu
      //       navMenuDiv.classList.add("hidden");
      //     }
      //   }
      // }
      // function checkParent(t, elm) {
      //   while (t.parentNode) {
      //     if (t === elm) {
      //       return true;
      //     }
      //     t = t.parentNode;
      //   }
      //   return false;
      // }

      
